export const PRAESIDIA_DATA = [
    {
        academiejaar: "1985 - 1986",
        praeses: "Geraard Tielemans",
        vice_praeses: "Paul Goossen",
        ab_actis: "Luc Bal",
        quaestor: "Bart De Keersmaecker",
        feestleider: "Philip Berghmans",
        spocul: "Willy Van Hoeck",
        schachtenmeester: "Stefan Bamps"
    },
    {
        academiejaar: "1986 - 1987",
        praeses: "Bart De Keersmaeker (Patrick Thysen)",
        vice_praeses: "Patrick Thyssen",
        ab_actis: "Daniel Huygh",
        quaestor: "Werner Van Hoof",
        feestleider: "Paul Goossens",
        spocul: "Luc Bal",
        schachtenmeester: "Cristiaan De Breuker"
    },
    {
        academiejaar: "1987 - 1988",
        praeses: "Paul Goossens",
        vice_praeses: "Werner Van Hoof",
        ab_actis: "Dirk Peeters",
        quaestor: "Cristiaan De Breuker",
        feestleider: "Kris Pleetinckx",
        spocul: "Paul Van Dessel",
        schachtenmeester: ""
    },
    {
        academiejaar: "1988 - 1989",
        praeses: "Cristiaan De Breuker",
        vice_praeses: "?",
        ab_actis: "?",
        quaestor: "Daniel Huygh",
        feestleider: "Peter Bosteels",
        spocul: "Nico Janssens",
        schachtenmeester: ""
    },
    {
        academiejaar: "1989 - 1990",
        praeses: "Ivo Lequesne",
        vice_praeses: "?",
        ab_actis: "?",
        quaestor: "?",
        feestleider: "?",
        spocul: "?",
        schachtenmeester: ""
    },
    {
        academiejaar: "1990 - 1991",
        praeses: "Frank De Vos",
        vice_praeses: "Bernard Kimpe",
        ab_actis: "Mark D' olieslages",
        quaestor: "Fidelis Faes",
        feestleider: "Frank Caethoven",
        spocul: "Dimitrie Blondeel",
        schachtenmeester: ""
    },
    {
        academiejaar: "1991 - 1992",
        praeses: "Frank Caethoven",
        vice_praeses: "Filip Renard",
        ab_actis: "Mark D' olieslages",
        quaestor: "Fidelis Faes",
        feestleider: "Stefaan Van de Vloed v. Stef",
        spocul: "Vincent Lescrauwaet",
        schachtenmeester: ""
    },
    {
        academiejaar: "1992 - 1993",
        praeses: "Stefaan Van de Vloed v. Stef",
        vice_praeses: "Frank Nees",
        ab_actis: "Geert Cleeren",
        quaestor: "Wim Meert",
        feestleider: "Erik Van Campenhout v. VC",
        spocul: "Steven Hoens",
        schachtenmeester: ""
    },
    {
        academiejaar: "1993 - 1994",
        praeses: "Gerry Vermeiren",
        vice_praeses: "Steven Hoens",
        ab_actis: "Geert Cleeren",
        quaestor: "Therry Van Geyseghem",
        feestleider: "Edwin De Vlieger",
        spocul: "Stefaan De Ridder",
        schachtenmeester: ""
    },
    {
        academiejaar: "1994 - 1995",
        praeses: "Erik Van Campenhout v. VC (Bart Clabots)",
        vice_praeses: "Bart Clabots",
        ab_actis: "Jurgen Van den Bossche",
        quaestor: "Jill Huygen",
        feestleider: "Philip Vander Meren",
        spocul: "Jim Wouters",
        schachtenmeester: ""
    },
    {
        academiejaar: "1995 - 1996",
        praeses: "Jim Wouters",
        vice_praeses: "Philip Vander Meren",
        ab_actis: "Filip Vanrentegem",
        quaestor: "Luc Gillis",
        feestleider: "Steven De Belser",
        spocul: "Tom Hamelrijckx v. Smiler",
        schachtenmeester: ""
    },
    {
        academiejaar: "1996 - 1997",
        praeses: "Luc Gillis",
        vice_praeses: "Stijn Mathys",
        ab_actis: "Carolin Wuytak",
        quaestor: "Tom Hamelrijckx v. Smiler",
        feestleider: "Wouter Brughmans",
        spocul: "Nico Vyncke",
        schachtenmeester: ""
    },
    {
        academiejaar: "1997 - 1998",
        praeses: "Diederik Stockbroeckx v. Die",
        vice_praeses: "Dirk Wyns",
        ab_actis: "Jim Wouters",
        quaestor: "Dirk Vermeulen v. Napsjaar",
        feestleider: "Carolin Wuytak",
        spocul: "Tom Hamelrijckx v. Smiler",
        schachtenmeester: ""
    },
    {
        academiejaar: "1998 - 1999",
        praeses: "Koen Laeremans v. Larrie",
        vice_praeses: "Koen Vits v. Tjenzo",
        ab_actis: "Dirk Wyns",
        quaestor: "Gunther Verhofstede",
        feestleider: "Sven Daelemans v. Daele",
        spocul: "Koen Brion v. Beton",
        schachtenmeester: ""
    },
    {
        academiejaar: "1999 - 2000",
        praeses: "Koen Vits v. Tjenzo",
        vice_praeses: "Jo Delmotte",
        ab_actis: "Jan Van Der Biest",
        quaestor: "Bart Scheurweghs",
        feestleider: "Koen Brion v. Beton",
        spocul: "Pieter Coosemas",
        schachtenmeester: ""
    },
    {
        academiejaar: "2000 - 2001",
        praeses: "Bart Scheurweghs",
        vice_praeses: "Wim Stiers",
        ab_actis: "Kasper Van Brabant",
        quaestor: "Jelle De Nayer",
        feestleider: "Frédéric Nagels",
        spocul: "Bert Hoirelbeke",
        schachtenmeester: ""
    },
    {
        academiejaar: "2001 - 2002",
        praeses: "Wim Stiers",
        vice_praeses: "Ilias Vertonghen",
        ab_actis: "Tom Huygens",
        quaestor: "Bram Delbeke",
        feestleider: "Jelle De Nayer",
        spocul: "Sam Vanderleyden v. Mas",
        schachtenmeester: ""
    },
    {
        academiejaar: "2002 - 2003",
        praeses: "Ilias Vertonghen",
        vice_praeses: "Carl Reynaert v. Charel",
        ab_actis: "Kristof Claeskens v. Dario",
        quaestor: "Tom Huygens",
        feestleider: "Freek Noppen v. Noppe",
        spocul: "Filip Verscuren v. Fonzie",
        schachtenmeester: ""
    },
    {
        academiejaar: "2003 - 2004",
        praeses: "Filip Verscuren v. Fonzie",
        vice_praeses: "Aram Van Dessel",
        ab_actis: "Wouter Beyen v. Kleine",
        quaestor: "John Tesseur v. John Q",
        feestleider: "Sven Verbessem",
        spocul: "Carl Reynaert",
        schachtenmeester: ""
    },
    {
        academiejaar: "2004 - 2005",
        praeses: "Jonas Van Sever v. Bonas",
        vice_praeses: "Carl Reynaert",
        ab_actis: "Peter Carels v. Peterke Palm",
        quaestor: "Wouter Beyen v. Kleine",
        feestleider: "Wouter Vanderelst v. Joewie",
        spocul: "Liesbeth Buntinx",
        schachtenmeester: ""
    },
    {
        academiejaar: "2005 - 2006",
        praeses: "Wouter Vanderelst v. Joewie",
        vice_praeses: "David Cochet",
        ab_actis: "Pieter Moers",
        quaestor: "Pieter De Henau v. Waldo",
        feestleider: "Wouter De Graeve v. Harige",
        spocul: "Stefan Van Den Brande v. Armani",
        schachtenmeester: ""
    },
    {
        academiejaar: "2006 - 2007",
        praeses: "Stefan Van Den Brande v. Armani",
        vice_praeses: "Dominique Cuylaert v. Domi",
        ab_actis: "Joachim Reniers v. Joa",
        quaestor: "Jan Willems v. Jan de Man",
        feestleider: "Philippe Van Deursen",
        spocul: "Joris Peeters",
        schachtenmeester: ""
    },
    {
        academiejaar: "2007 - 2008",
        praeses: "Bert Boulanger v. Bertje",
        vice_praeses: "Jochen Van Aelst v. Witte",
        ab_actis: "Michaël Henderickx v. Grune",
        quaestor: "Maarten Verheyen v. Peuk",
        feestleider: "",
        spocul: "",
        schachtenmeester: ""
    },
    {
        academiejaar: "2008 - 2009",
        praeses: "Koen Van Genechten v. Kiwi",
        vice_praeses: "Bert Boulanger v. Bertje",
        ab_actis: "Maarten De Koning",
        quaestor: "Thijs Wauters v. Alain",
        feestleider: "Simon Roggen",
        spocul: "Nick Van Herck",
        schachtenmeester: ""
    },
    {
        academiejaar: "2009 - 2010",
        praeses: "Koen Van Genechten v. Kiwi",
        vice_praeses: "Thijs Wauters v. Alain",
        ab_actis: "Ellen Vanhees",
        quaestor: "Bassie",
        feestleider: "Gert Kemps",
        spocul: "Stef Verhelst",
        schachtenmeester: ""
    },
    {
        academiejaar: "2010 - 2011",
        praeses: "Steven Embrechts",
        vice_praeses: "Ellen Vanhees",
        ab_actis: "Kevin Dictus",
        quaestor: "Tom Kerselaers",
        feestleider: "Kevin Hermans",
        spocul: "Kevin Schoutens",
        schachtenmeester: ""
    },
    {
        academiejaar: "2011 - 2012",
        praeses: "Kevin Dictus v. BMX",
        vice_praeses: "Jens Costers",
        ab_actis: "Thomas Van Winckel",
        quaestor: "Lennox Destombes v. Slets (Jens Farasyn)",
        feestleider: "Kevin Schoutens",
        spocul: "Nicolas De Poorter",
        schachtenmeester: ""
    },
    {
        academiejaar: "2012 - 2013",
        praeses: "Kevin Dictus v. BMX",
        vice_praeses: "Kevin Verschueren",
        ab_actis: "Timothy Callemein",
        quaestor: "Wouter De Schutter v. Schütze",
        feestleider: "Jens Roux",
        spocul: "Daan Gybels",
        schachtenmeester: ""
    },
    {
        academiejaar: "2013 - 2014",
        praeses: "Rafaël Vercammen v. Raf",
        vice_praeses: "Daan Gybels v. Dakke",
        ab_actis: "Tom Hermans",
        quaestor: "Wouter De Schutter v. Schütze",
        feestleider: "Kyani Vullings",
        spocul: "Stef Van Wolputte",
        schachtenmeester: ""
    },
    {
        academiejaar: "2014 - 2015",
        praeses: "Lukas Van Der Motte v. Stickers",
        vice_praeses: "Wouter De Schutter v. Schütze",
        ab_actis: "Liesl Moriau",
        quaestor: "Steven Blommaart",
        feestleider: "Nore Storms",
        spocul: "Wouter Pinxten",
        schachtenmeester: ""
    },
    {
        academiejaar: "2015 - 2016",
        praeses: "Wannes Beckers v. Baygon",
        vice_praeses: "Wouter Pinxten",
        ab_actis: "Dries Vanderwilt v. Modo",
        quaestor: "David Jacquemin v. Wolf",
        feestleider: "Tim Dewit",
        spocul: "Lukas Van Der Motte v. Stickers",
        schachtenmeester: ""
    },
    {
        academiejaar: "2016 - 2017",
        praeses: "Wouter De Schutter v. Schütze",
        vice_praeses: "David Jacquemin v. Wolf",
        ab_actis: "Stijn Geysels",
        quaestor: "Ben Van Cammeren",
        feestleider: "Jason Elzen v. Jameson",
        spocul: "Yves Denutte",
        schachtenmeester: "David Jacquemin v. Wolf"
    },
    {
        academiejaar: "2017 - 2018",
        praeses: "Tine Vervloet v. Tineke",
        vice_praeses: "Liesl Moriau v. Weasel",
        ab_actis: "Frederik Holemans v. Sus",
        quaestor: "Stefan Corbeel v. Klus",
        feestleider: "Jannes Eyckmans v. Feestjanus",
        spocul: "Yves Denutte v. Blauwvoet",
        schachtenmeester: "Liesl Moriau v. Weasel"
    },
    {
        academiejaar: "2018 - 2019",
        praeses: "Wannes Roels",
        vice_praeses: "Anthonie Ghielen v. d'Hollander",
        ab_actis: "Wannes Beckers v. Baygon",
        quaestor: "Stien Gillis",
        feestleider: "Matty Van den Eynden",
        spocul: "Mike Goetmaekers",
        schachtenmeester: "Wouter Cools v. Günter"
    },
    {
        academiejaar: "2019 - 2020",
        praeses: "Wouter Cools v. Günter",
        vice_praeses: "Stien Gillis v. The Machine",
        ab_actis: "Ruben Vermeylen",
        quaestor: "Stef Venken v. Arrogante Limburger",
        feestleider: "Aswin Driesen",
        spocul: "Christoff Van Dessel",
        schachtenmeester: "Pieter Reynaerts"
    },
    {
        academiejaar: "2020 - 2021",
        praeses: "Jason Elzen v. Jameson",
        vice_praeses: "Ruben Vermeylen",
        ab_actis: "Wouter Cools v. Günther",
        quaestor: "Stef Venken v. Arrogante Limburger",
        feestleider: "Toon Jansens v. Djuk",
        spocul: "Giel Vlassak",
        schachtenmeester: "Wouter Cools v. Günther"
    },
    {
        academiejaar: "2021-2022",
        praeses: "Stef Venken v. Arrogante Limburger",
        vice_praeses: "Keanu Stitschinsky v. Stits",
        ab_actis: "Lennerd Van Opstal",
        quaestor: "Matthias Hendrickx",
        feestleider: "Jason Elzen v. Jameson",
        spocul: "Jonah Alaerts",
        schachtenmeester: "Alec van Langendonck"
    },
    {
        academiejaar: "2022-2023",
        praeses: "Lennerd Van Opstal",
        vice_praeses: "Yorrit Vos v. Bloemist",
        ab_actis: "Lars Bruyneels",
        quaestor: "Matthias Hendrickx",
        feestleider: "Jorte Verheyen",
        spocul: "Frederik Theunis",
        schachtenmeester: "Jente De Leus"
    },
    {
        academiejaar: "2023-2024",
        praeses: "Frederik Theunis",
        vice_praeses: "Jorte Verheyen",
        ab_actis: "Rune Wagemans",
        quaestor: "Yorrit Vos",
        feestleider: "Wout Mellaerts",
        spocul: "Jarne Vreys",
        schachtenmeester: "Lars Bruyneels"
    },
    //***add new praesidium above this line.***
];

/*
ADD ONE BELOW EVERY YEAR WITH THE NEW PRAESIDIUM

            {
                academiejaar: "",
                praeses: "",
                vice_praeses: "",
                ab_actis: "",
                quaestor: "",
                feestleider: "",
                spocul: "",
                schachtenmeester: ""
            },
 */

