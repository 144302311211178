import React from "react";
import MeretaBanner from "../../images/bannerMereta.webp";
import {Link} from "react-router-dom";
import styled from "@emotion/styled";

function Navigation(props) {
    const {open, setOpen} = props;

    return (
        <StyledNavBar>
            <FlexL>
                <Link to={"/"}>
                    <Schild src={MeretaBanner} alt={"KVHC Mereta"}/>
                </Link>
            </FlexL>
            <FlexR open={open}>
                <NavList>
                    <NavItem onClick={() => setOpen(false)}><NavLink to={"/over_ons/geschiedenis"}>Over
                        ons</NavLink></NavItem>
                    <NavItem onClick={() => setOpen(false)}><NavLink
                        to={"/activiteiten"}>Activiteiten</NavLink></NavItem>
                    <NavItem onClick={() => setOpen(false)}><NavLink to={"/clubhuis"}>Clubhuis</NavLink></NavItem>
                    <NavItem onClick={() => setOpen(false)}><NavLink to={"/partners"}>Partners</NavLink></NavItem>
                    <NavItem onClick={() => setOpen(false)}><NavLink to={"/contact"}>Contact</NavLink></NavItem>
                    <NavItem onClick={() => setOpen(false)}><NavLink to={"/alumni"}>Alumni</NavLink></NavItem>
                </NavList>
            </FlexR>
        </StyledNavBar>
    )
}

const StyledNavBar = styled.div`
  position: fixed;
  text-align: center;
  background-image: linear-gradient(to bottom, #033864, #034073);
  color: white;
  top: 0; 
  left: 0;
  right: 0;
  width: 100%;
  height: 56px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 10;
  box-shadow: 0px 0px 6px 1px #000000;
`;

const NavList = styled.ul`
  list-style: none;
  padding: 10px 8px;
  margin: 0;
`;


const NavItem = styled.li`
  list-style: none;
  padding-left: 8px;
  padding-right: 8px;
  letter-spacing: 1.175px;
  font-size: 16px;
  float: left;
  background-image: linear-gradient(white, #d2d2d2);
  background-size: 0% 0.1em;
  background-position-y: 100%;
  background-position-x: 50%;
  background-repeat: no-repeat;
  transition: background-size 0.5s ease-in-out;
    &:hover {
      background-size: 100% 0.1em;
    }
  @media (max-width: 1250px) {
    padding-top: 7px;
    font-size: 17px;
    color: black;
  }
`;

const NavLink = styled(Link)`
  font-family: 'Oswald', sans-serif;
  text-decoration: none;
  color: white;
  &:hover {
    text-decoration: none;
    color: white;
  }
  @media (max-width: 1250px) {
    color: black;
    &:hover {
    color: black;
    }
  }
`;

const Schild = styled.img`
  height: 50px;
`;

const FlexL = styled.div`
  padding-left: 15vw;
  @media (max-width: 1250px) {
    padding-left: 0;
  }
`;

const FlexR = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 15vw;
  flex-flow: row nowrap;
  z-index: 10;
    @media (max-width: 1250px) {
    padding-right: 5vw;
    justify-content: normal;
    flex-flow: column nowrap;
    background-color: white;
    color: black;
    position: fixed;
    transform: ${({open}) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 200px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
  }
`;


export default Navigation;
