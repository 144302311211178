import styled from "@emotion/styled";

export const C = styled.span`
  color: #e60314;
`;

export const PreSlanted = styled.div`
  padding-bottom: 120px;
`;

export const SlantedWhite = styled.div`
    box-sizing: border-box;
    width: 100%;
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
  &:before {
    content: "";
    background: white;
    height: 80px;
    transform: skewY(1.5deg);
    position: absolute;
    left: 0;
    right: 0;
    top: -30px;
    z-index: -10;
  }
  &:after{
    content: "";
    background: white;
    height: 80px;
    transform: skewY(-1.5deg);
    position: absolute;
    left: 0;
    right: 0;
    bottom: -30px;
    z-index: -10;
  }
`;

export const SlantedBlueL = styled(SlantedWhite)`
  background: #034073;
  padding-top: 80px;
  padding-bottom: 80px;
  color: white;
    &:before {
    background: #034073;
    transform: skewY(-1.5deg);
    z-index: -1;
    }
    &:after {
    background: #034073;
    z-index: -1;
    }
`;

export const SlantedBlueR = styled(SlantedWhite)`
  background: #034073;
  padding-top: 80px;
  padding-bottom: 80px;
  color: white;
    &:before {
    background: #034073;
    transform: skewY(1.5deg);
    z-index: -1;
    }
    &:after {
    background: #034073;
    transform: skewY(1.5deg);
    z-index: -1;
    }
`;

export const ImgResponsive = styled.img`
  width: 100%;
  border-radius: 5px;
`;

export const TopPadding = styled.div`
  padding-top: 56px;
`;

export const BottomPadding = styled.div`
  padding-bottom: 85px;
`;
