import React from "react";
import {BottomPadding, C, ImgResponsive, TopPadding} from "../../ui/multiUseStyle";
import Container from "react-bootstrap/cjs/Container";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import Polaroid from "../../images/polaroidsSmall.webp"


function Clubhouse() {
    return (
        <>
            <TopPadding/>
            <Container className={"mb-4"}>
                <br/>
                <Row>
                    <Col lg={6}>
                        <h1><C>C</C>lubhuis</h1>
                        <br/>
                        <p>
                            Een eigen clubhuis is voor vele studentenclubs een droom, voor Mereta is dit de realiteit. Al
                            vele jaren waren er ideeën om ergens een vaste uitvalsbasis te voorzien waar de leden van
                            Mereta steeds welkom zijn. Zo is het oog van Mereta op het voormalige jeugdhuis van
                            Elzenstraat gevallen. Het pand vlak naast het prachtige net gerestaureerde
                            Sint-Michielskasteel aan het Sint-Michielspark stond al een tijd leeg en was na enkele
                            klusjes klaar voor gebruik. In de zomer van 2019 werd de remise, het voormalige koetshuis
                            van het kasteel omgevormd tot het clubhuis van Mereta. Het Clubhuis beschikt met onder
                            andere een bar, vergaderzaal en polyvalentente ruimte over alles wat nodig is voor een goede
                            werking van onze club.
                        </p>
                        <h5>Open elke vrijdag vanaf 19u.</h5>
                    </Col>
                    <Col lg={6}>
                        <ImgResponsive src={Polaroid} alt={"Clubhuis"}/>
                    </Col>
                </Row>
                <BottomPadding/>
            </Container>
        </>
    )
}

export default Clubhouse;
