import React from "react";
import styled from "@emotion/styled";
import {BottomPadding, C, TopPadding} from "../../ui/multiUseStyle";
import Container from "react-bootstrap/cjs/Container";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import Mereta from "../../images/clubhuisContact.webp";
import DeNayer from "../../images/deNayerContactPage.webp";

function Contact() {
    return (
        <>
            <TopPadding/>
            <Container>
                <br/>
                <h1><C>C</C>ontact</h1>
                <Row>
                    <Col lg={6} className={"pb-5, pr-4"}>
                        <Row className={"pb-3"}>
                            <ContactImg src={Mereta} alt={"Mereta Clubhuis"}/>
                        </Row>
                        <Row className={"pb-3"}>
                            <h2>MERETA CLUBHUIS</h2>
                            <p>Clubhuis gevestigd langs het Sint-Michiels kasteel. Een plek waar men samenkomt voor een
                                frisse pint en fijne gesprekken.</p>
                        </Row>
                        <Row className={"pb-3"}>
                            <ContactRef
                                href={"mailto:info@mereta.be?subject=Contact"}><ContactButton1>CONTACT</ContactButton1></ContactRef>
                            <GoogleMap
                                src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1581.9660548826969!2d4.486532657196578!3d51.059797635853684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3e50374062b93%3A0x856d33d8a134b221!2sGroenstraat%2011b%2C%202860%20Sint-Katelijne-Waver!5e1!3m2!1sen!2sbe!4v1613069675160!5m2!1sen!2sbe"}
                                frameBorder={0} allowFullScreen={""}
                                aria-hidden={false} tabIndex={0}/>
                        </Row>
                    </Col>
                    <Col lg={6} className={"pb-5, pl-4"}>
                        <Row className={"pb-3"}>
                            <ContactImg src={DeNayer} alt={"Campus De Nayer"}/>
                        </Row>
                        <Row className={"pb-3"}>
                            <h2>POSTBUS DE NAYER</h2>
                            <p>Campus De Nayer is dé technologiecampus bij uitstek. Gelegen tussen Antwerpen en Brussel
                                en vlakbij Mechelen. </p>
                        </Row>
                        <Row className={"pb-3"}>
                            <ContactRef
                                href={"mailto:info@mereta.be?subject=Contact"}><ContactButton2>CONTACT</ContactButton2></ContactRef>
                            <GoogleMap
                                src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.398591931091!2d4.497753415960163!3d51.06760425058392!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3e4e14a4b924b%3A0x76def892ed4baf5!2sKU%20Leuven%20Technology%20Campus%20De%20Nayer!5e1!3m2!1sen!2sbe!4v1613069759083!5m2!1sen!2sbe"}
                                frameBorder={0} allowFullScreen={""}
                                aria-hidden={false} tabIndex={0}/>
                        </Row>
                    </Col>
                </Row>
                <BottomPadding/>
            </Container>
        </>
    )
}

const ContactImg = styled.img`
  width: 100%;
  padding-right: 1vw;
  @media (max-width: 1250px) {
      padding-left: 2vw;
      padding-right: 2vw;
  }
`;

const ContactButton1 = styled.button`
    width: 120px;
    background-color: #034073;
    color: white;
    border: none;
    padding: 0.3em;
    text-decoration: none !important;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.25em;
    opacity: 0.8;
    margin-bottom: 2vh;
    transition: 0.4s;
    &:hover {
      background-color: #021b30;
    }
`;

const ContactButton2 = styled.button`
    width: 120px;
    background-color: #e60314;
    color: white;
    border: none;
    padding: 0.3em;
    text-decoration: none !important;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.25em;
    opacity: 0.8;
    margin-bottom: 2vh;
    transition: 0.4s;
    &:hover {
      background-color: #92020c;
    }
`;

const ContactRef = styled.a`
    text-decoration: none !important;
    color: white;
    &:hover {
      color: white;
    }
    @media (max-width: 992px) {
      margin-left: 3vw;
    }
`;

const GoogleMap = styled.iframe`
  width: 95%;
  height: 500px;
  border: 0;
  @media (max-width: 1250px) {
    width: 100%;
    height: 350px;
  }
`;

export default Contact;
