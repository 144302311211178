import React from "react";
import styled from "@emotion/styled";
import {BottomPadding, C} from "../../ui/multiUseStyle";
import {PRAESIDIA_DATA} from "../../data/praesidiaData";
import Container from "react-bootstrap/cjs/Container";
import Accordion from "react-bootstrap/cjs/Accordion";
import Card from "react-bootstrap/cjs/Card";

function Praesidia() {
    return (
        <Container>
            <br/>
            <h1><C>P</C>raesidia</h1>
            <StyledAccordion>
                {PRAESIDIA_DATA.map((p) =>
                <Card key={p.academiejaar}>
                    <Accordion.Toggle as={Card.Header} eventKey={p.academiejaar}>
                        <Bold>{p.academiejaar}</Bold>  ·  {p.praeses}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={p.academiejaar}>
                        <Card.Body>
                            <Bold>Praeses:</Bold> {p.praeses}<br/>
                            <Bold>Vice-Praeses:</Bold> {p.vice_praeses}<br/>
                            <Bold>Ab-Actis:</Bold> {p.ab_actis}<br/>
                            <Bold>Quaestor:</Bold> {p.quaestor}<br/>
                            <Bold>Feestleider:</Bold> {p.feestleider}<br/>
                            <Bold>Spocul:</Bold> {p.spocul}<br/>
                            <Bold>Schachtenmeester:</Bold> {p.schachtenmeester}<br/>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                )}
            </StyledAccordion>
            <StyledTable>
                <thead>
                <StyledHeadRow>
                    <StyledTableHead>Academiejaar</StyledTableHead>
                    <StyledTableHead>Praeses</StyledTableHead>
                    <StyledTableHead>Vice-Praeses</StyledTableHead>
                    <StyledTableHead>Ab-Actis</StyledTableHead>
                    <StyledTableHead>Quaestor</StyledTableHead>
                    <StyledTableHead>Feestleider</StyledTableHead>
                    <StyledTableHead>Spocul</StyledTableHead>
                    <StyledTableHead>Schachtenmeester</StyledTableHead>
                </StyledHeadRow>
                </thead>
                <tbody>
                {PRAESIDIA_DATA.map((p) =>
                    <StyledTableRow key={p.academiejaar}>
                        <StyledYear>{p.academiejaar}</StyledYear>
                        <StyledData>{p.praeses}</StyledData>
                        <StyledData>{p.vice_praeses}</StyledData>
                        <StyledData>{p.ab_actis}</StyledData>
                        <StyledData>{p.quaestor}</StyledData>
                        <StyledData>{p.feestleider}</StyledData>
                        <StyledData>{p.spocul}</StyledData>
                        <StyledData>{p.schachtenmeester}</StyledData>
                    </StyledTableRow>
                )}
                </tbody>
            </StyledTable>
            <br/>
            <BottomPadding/>
        </Container>
    )
}


const StyledTable = styled.table`
    border-collapse: collapse;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    @media (max-width: 999px) {
      display: none;
    }
`;

const StyledAccordion = styled(Accordion)`
    @media (min-width: 1000px) {
      display: none;
    }
`;

const Bold = styled.span`
    font-weight: bold;
`;

const StyledHeadRow = styled.tr`
    background-color: #034073;
    color: #ffffff;
    text-align: left;
    font-size: 1.1em;
`;

const StyledTableHead = styled.th`
    padding: 5px 10px 10px 10px;
`;

const StyledYear = styled.td`
     padding: 2px 6px;
     min-width: 115px;
     font-weight: bold;
`;

const StyledData = styled.td`
    padding: 2px 6px;
`;

const StyledTableRow = styled.tr`
    border-bottom: 1px solid #dddddd;
`;


export default Praesidia
