import React from "react";
import styled from "@emotion/styled";
import Container from "react-bootstrap/cjs/Container";
import {
    BottomPadding,
    C,
    ImgResponsive,
    PreSlanted,
    SlantedBlueL,
    SlantedBlueR,
    SlantedWhite,
    TopPadding
} from "../../ui/multiUseStyle";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import OpenLuchtTD from "../../images/oltdParty.webp";
import Sport from "../../images/kickerSport.webp";
import Feest from "../../images/feestProf.webp";
import Cultuur from "../../images/cultuur.webp";
import Cantus from "../../images/cantus.webp";
import {FacebookIcon} from "../icons/FacebookIcon";

function Activities() {
    return (
        <>
            <TopPadding/>
            <PreSlanted>
                <Container>
                    <br/>
                    <h1><C>A</C>ctiviteiten</h1>
                    <Subtext>
                        <a target={"_blank"}
                           href={"https://www.facebook.com/KVHC-Mereta-444705929033808"} rel={"noreferrer"}
                           style={{fill: "#4267B2", display: "inline-block", height: "20px", width: "20px"}}>
                            <FacebookIcon/>
                        </a> Volg onze <a target={"_blank"}
                                          href={"https://www.facebook.com/KVHC-Mereta-444705929033808"}
                                          rel={"noreferrer"}>Facebook</a> om
                        alle evenementen op de voet volgen.</Subtext>
                    <br/>
                    <Row>
                        <Col sm={8}>
                            <h1><C>O</C>penlucht TD</h1>
                            <p>
                                Traditioneel sluit Mereta het academiejaar af met een geweldige OPENLUCHT TD.
                                Fantastische Dj’s, de beste muziek, flitsende spots, honderden liters drank en de
                                schitterende locatie rond Kasteelruïne Ter Elst zorgden jaarlijks voor een onvergetelijk
                                einde van het academiejaar. Met gemiddeld 1500 bezoekers is dit evenement uitgegroeid
                                tot een van de grootste studentenfuiven uit de regio. Door veiligheidsredenen is de
                                OPENLUCT TD moeten verhuizen, eerst naar het domein De Locht en dan later naar de
                                spoorweglaan tegenover de brandweerkazerne van Duffel. Tijdens de laatste editie, bij
                                het 20 jarige bestaan trokken we maar liefst 2500 bezoekers!
                            </p>
                        </Col>
                        <Col sm={4}>
                            <ImgResponsive src={OpenLuchtTD}/>
                        </Col>
                    </Row>
                </Container>
            </PreSlanted>
            <SlantedBlueL>
                <Container>
                    <Row>
                        <Col sm={4}>
                            <ImgResponsive src={Cantus}/>
                        </Col>
                        <Col sm={8}>
                            <h1><C>C</C>antus ad codicem</h1>
                            <p>
                                Deze cantus is het studentikoze hoogtepunt van het academiejaar en verloopt
                                traditiegetrouw, ordelijk en conform de clubcodex. De cantus wordt voorgezeten door de
                                nationaal praeses en wordt voorzien van een professionele muzikale begeleiding (piano en
                                vocaal). Naast onze eigen leden wonen heel wat andere traditionele studenten en
                                genodigden van over gans Vlaanderen deze avond bij.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </SlantedBlueL>
            <SlantedWhite>
                <Container>
                    <Row>
                        <Col sm={8}>
                            <h1><C>C</C>ultuur</h1>
                            <p>
                                Een studentenvereniging als Mereta weet waar hun oorsprong ligt. Daarom draagt Mereta de
                                studentikoze cultuur hoog in het vaandel. Het is namelijk een eer om student te zijn!
                                Daarom zijn onze cantussen een gezellige samenloop van zang en pint zonder dat daar
                                enige marginaliteit aan gekoppeld hoeft te zijn. Op deze manier slagen wij erin om ook
                                onze alumni massaal op deze avonden aanwezig te hebben. Want: Student zijn dat is men of
                                dat is men niet.
                            </p>
                            <p>
                                Daarnaast worden er elk jaar andere cultuurevenementen georganiseerd zoals een
                                brouwerijbezoek en uitstappen naar Duitsland of Oostenrijk. Zo wordt er elk jaar in
                                Wenen een bal georganiseerd waarop Mereta te gast is.
                            </p>
                        </Col>
                        <Col sm={4}>
                            <ImgResponsive src={Cultuur}/>
                        </Col>
                    </Row>
                </Container>
            </SlantedWhite>
            <SlantedBlueR>
                <Container>
                    <Row>
                        <Col sm={4}>
                            <ImgResponsive src={Feest}/>
                        </Col>
                        <Col sm={8}>
                            <h1><C>F</C>eest</h1>
                            <p>
                                Naast studeren moet een student af en toe ook eens stoom aflaten. De ideale manier om
                                dit te doen is door eens goed te gaan feesten. Daarom organiseren wij ook elk jaar zelf
                                feestjes, zoals de Oktoberfest TD, Ladies For Free TD en dé Openlucht TD. Daarnaast zijn
                                wij ook altijd terug te vinden op studentenfuiven in Mechelen of In het gezellige
                                Sint-Katelijne-Waver.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </SlantedBlueR>
            <SlantedWhite>
                <Container>
                    <Row>
                        <Col sm={8}>
                            <h1><C>S</C>port</h1>
                            <p>
                                Naast de ontspannende sportieve activiteiten zoals muurklimmen, bowling, karten en
                                paintball neemt Mereta elk jaar deel aan een voet –en volleybalcompetitie. Verschillende
                                studentenverenigingen nemen het tegen elkaar op in een poulesysteem en proberen tot in
                                de uiteindelijke finale te komen. Mereta speelde al meermaals kampioen, in 2020 werden
                                we nog vice-kampioen voetbal.
                            </p>
                            <Row>
                                <Col>
                                    <span>Voetbal</span>
                                    <ul>
                                        <li>1999-2000: Vice-kampioen voetbal</li>
                                        <li>2000-2001: Kampioen voetbal</li>
                                        <li>2003-2004: Vice-kampioen voetbal</li>
                                        <li>2007-2008: Kampioen voetbal</li>
                                        <li>2019-2020: Vice-kampioen voetbal</li>
                                        <li>2022-2023: Kampioen voetbal</li>
                                    </ul>
                                </Col>
                                <Col>
                                    <span>Volleybal</span>
                                    <ul>
                                        <li>1997-1998: Vice-kampioen volleybal</li>
                                        <li>2000-2001: Kampioen volleybal</li>
                                        <li>2002-2003: Vice-kampioen volleybal</li>
                                        <li>2018-2019: Vice-kampioen volleybal</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={4}>
                            <ImgResponsive src={Sport}/>
                        </Col>
                    </Row>
                </Container>
            </SlantedWhite>
            <BottomPadding/>
        </>
    )
}

const Subtext = styled.p`
    font-style: italic;
`;


export default Activities;
