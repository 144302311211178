import styled from "@emotion/styled";
import React from "react";
import {BottomPadding, C} from "../../ui/multiUseStyle";
import MeretaOld from "../../images/meretaOld.gif";
import MeretaSomers from "../../images/meretaMechelenSomers.webp";
import Container from "react-bootstrap/cjs/Container";

function History() {
    return (
        <Container>
            <br/>
            <h1><C>I</C>ntro</h1>
            <p>
                Mereta is een onafhankelijke studentenvereniging die alle studenten van Campus De Nayer te
                Sint-Katelijne-Waver groepeert. Onze vereniging vertegenwoordigt deze studenten en verdedigt hun
                belangen zowel binnen als buiten de hogeschool.
            </p>
            <p>
                Mereta is een creatieve, stabiele en geëngageerde vereniging die stijl, opvoeding en algemene cultuur
                belangrijk vindt. Samen met onze Alumni brengen wij studenten met elkaar in contact. Doelgroep van onze
                acties zijn in eerste orde de studentengemeenschap en in tweede orde de bredere samenleving. De
                studenten zorgen daarbij voor instroom, activiteit en vernieuwing, terwijl oud-studenten voor
                ondersteuning, stabiliteit en maatschappelijke relevantie en implicatie in zijn meest brede zin zorgen.
                De wetten van de Clubcodex liggen aan onze basis maar we beseffen al te goed dat we een hedendaagse,
                dynamische studentenvereniging zijn, gericht op de toekomst!
            </p>
            <p>
                De taak die voor onze leden weggelegd is, is niet alleen het verwerven van een diploma, maar wellicht
                nog meer het volledig ontplooien van zijn of haar talenten. Mereta biedt het ideale kader om zowel het
                intellectueel, organisatorisch als leidinggevend kunnen ten volle te benutten. Mereta verkiest
                persoonlijkheid boven individualiteit, gemeenschap boven massa, kwaliteit boven kwantiteit. Een
                gemeenschap zoals de onze leeft door de ideeën, het engagement en de creativiteit van de leden. De eigen
                persoonlijkheid en de eigen talenten zinvol in een gemeenschap brengen en deze verder ontwikkelen is een
                opdracht die sommige studenten enkel in het latere beroepsleven ervaren. Bij Mereta is dit een
                dagelijks, algemeen-vormend onderdeel van de opleiding.
            </p>
            <h1><C>H</C>et begin</h1>
            <OldImg src={MeretaOld} alt={"Mereta Schild"}/>
            <p>
                Op 17 april 1985 verklaart de Minister van Onderwijs zich akkoord met het voorstel van toenmalige
                directie van de Katholieke Industriële Hogeschool De Nayer, om afdeling Klinische scheikunde om te
                vormen tot de afdeling meet- en regeltechniek. Hiermee was het startschot gegeven voor de opleiding tot
                gegradueerde in de meet- en regeltechniek. De heer ir. Felique deed het voorbereidende werk en bracht
                het, met ingang van het academiejaar 1985-1986, graduaat meet- en regeltechniek aan de Katholieke
                Industriële Hogeschool De Nayer tot stand.
            </p>
            <p>
                Het idee om voor het graduaat een aparte studentenclub op te richten kwam in eerste instantie ook van de
                hogeschool zelf. Toenmalig directeur ir. Verwilt polste bij het KVTHVM of het mogelijk was een aparte
                club voor de graduaten op te richten. Er werd een kick-off meeting in de Fruitenborgh georganiseerd
                gevolgd door een infoavond voor de meet- en regel studenten. Uiteindelijk telde Mereta in haar eerste
                jaar 19 leden, praesidium-leden inbegrepen.
            </p>
            <p>
                Naast de cursusdienst hield Mereta zich vooral bezig met de klassieke activiteiten; bowling, sjotten,
                cantussen, TD's, en een Lesse-afvaart. Verder was het studentenleven in Sint Katelijne Waver tijdens de
                tweede helft van de jaren '80 bijzonder ‘sport minded'. Qua sport op De Nayer en/of in de stamkroeg was
                het al basketbal wat de klok sloeg. De verenigingsstudenten waren dan ook de meest fervente aanhangers
                van Racing MaesPils Mechelen. En of die nu thuis speelden of op verplaatsing, week na week stonden er
                pakken brullende studenten op de tribune, hun kelen schor te krijsen. Kwestie van na de match de slikker
                deftig te kunnen spoelen ...
            </p>
            <p>
                In 1995-‘96 worden in het graduaat elektromechanica drie volwaardige optierichtingen uitgebouwd: optie
                Meet en Regeltechniek, optie Klimatisatie en optie Onderhoudstechnieken. De eerste drie semesters zijn
                gemeenschappelijk (d.w.z het volledige eerste jaar en het eerste semester van het tweede jaar). De
                resterende drie semesters verschillen inhoudelijk volgens optie. Toch werd om historische redenen de
                naam Mereta, die staat voor ‘Meet- en Regel Technieken Ambiance’ behouden. Tot de eeuwwisseling
                veranderde er verder niet al te veel, Mereta bleef zich hoofdzakelijk bezighouden met het aanbieden van
                ontspannende activiteiten.
            </p>
            <h1><C>D</C>e ommekeer</h1>
            <SomersImg src={MeretaSomers} alt={""}/>
            <p>
                Vanaf de eeuwwisseling ontstond er een nieuwe stroming binnen Mereta die de nadruk ging leggen op de
                innerlijke vorming van de student, organisatie en studentikoziteit. We slaagden erin het studentenleven
                op te voeren tot op een hoog studentikoos en cultureel peil. De activiteiten waren van opmerkelijke
                kwaliteit. Het engagement en de ambitie van de leden stelde de vereniging in staat grote evenementen te
                organiseren zoals de jaarlijkse OPENLUCHT TD met jaarlijks meer dan 2000 bezoekers! De exclusieve
                OPENINGS TD’s in de COPACABANA, de VALENTINE’S MESSAGE PARTY in de stadsfeestzaal te Mechelen zijn
                ondertussen ook legendarisch. Op socio-cultureel en sportief vlak manifesteerde Mereta zich door
                meerdere malen kampioen te spelen in een volley- en voetbalcompetitie voor Katelijnse
                studentenverenigingen, en uit eerbetoon aan ‘de driedaagse fietsen op rollen’ werd vanaf 2005 met groot
                succes de ‘memorial fietsen op rollen’ georganiseerd, een geliefd volksfeest in de achterzaal van café
                de club. Mereta wist zich in het Mechelse studentenleven ook te positioneren als (moreel) leidinggevend
                inzake studentikoziteit door het cultiveren van traditionele studentikoziteit. Er werden vormingsavonden
                en modelcantussen gehouden, linten werden aangepast naar de normen van de Clubcodex en er werd
                overgegaan tot het invoeren van studentenpetten, het studentikoze symbool bij uitstek!
            </p>
            <p>
                In het academiejaar 1999-2000 werd de afdeling graduaat elektronica van het NARAFI (opgericht in 1939)
                geleidelijk overgeheveld naar campus De Nayer en in 2004 werd het Kardinaal Mercier Instituut verhuisd
                naar Sint Katelijne Waver. Ook in 2004 werd voor het hele hoger onderwijs in Vlaanderen de
                bachelor-masterstructuur (BAMA) van kracht en spreekt men niet langer van graduaten en ingenieurs, maar
                van en professionele bachelors en masters. Sinds september 2012 zijn de professionele
                bacheloropleidingen onderdeel van de Hogeschool Thomas More, de academische opleidingen zijn sinds
                oktober 2013 onderdeel van de KU Leuven.
            </p>
            <p>
                Toen er in 2010 geruchten de ronde gingen over een mogelijke verhuizing van campus De Nayer naar de
                stationsbuurt in Mechelen trok Mereta proactief naar de Mechelse burgemeester Somers met een
                eisenpakket. Zo moesten ondermeer; gratis parkeerplaats, voldoende kwalitatieve labo's en genoeg
                fuifzalen gevrijwaard worden.
            </p>
            <h1><C>D</C>e hervorming</h1>
            <p>
                In de loop van de jaren '10 (2010 - 2019) wordt er verder ook luidop nagedacht over de
                organisatiestructuur van de vereniging en, vooral om nieuwe leden beter te binden aan de vereniging,
                hervormt Mereta zichzelf in 2016 naar een volwaardige club door het (her)invoeren van een
                schachtenwerking.
            </p>
            <p>
                De BAMA-structuur zorgde er ondertussen ook voor dat vele commilitones hun studies gingen verderzetten
                met een masteropleiding aan de campus. Via schakelprogramma’s en een systeem van studiepunten werden
                studiejaren en opleidingsgraden gecombineerd waardoor de voordien duidelijke grenzen tussen de
                verschillende graden verdwenen. Hierdoor werd het voor Mereta minder relevant enkel de professionele
                bachelor studenten te groeperen. In 2019 werd er via een algemeen convent beslist om het lidmaatschap
                open te stellen voor alle studenten van campus De Nayer.
            </p>
            <p>
                Een ander hoogtepunt in deze periode was ongetwijfeld de organisatie van een seminarie voor het Europees
                Verbond (EKV) in Mechelen, een driedaagse met naast een vormend en een studentikoos gedeelte ook de
                jaarlijkse algemene vergadering van het EKV. Verschillende internationale studentenbonden en de
                plaatselijke pers versloegen dit seminarie unaniem lovend, het was ook de aanzet tot opname van de KVSR
                in het EKV.
            </p>
            <br/>
            <BottomPadding/>
        </Container>
    )
}

const OldImg = styled.img`
  float: left;
  margin-right: 15px;
`;

const SomersImg = styled.img`
  float: right;
  width: 350px;
  border-radius: 8px;
  margin-left: 15px;
`;

export default History;
