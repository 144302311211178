import styled from "@emotion/styled";
import React from "react";
import Container from "react-bootstrap/cjs/Container";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import {FacebookIcon} from "./icons/FacebookIcon";
import {InstagramIcon} from "./icons/InstagramIcon";
import {LinkedInIcon} from "./icons/LinkedInIcon";

function Footer() {
    return (
        <StyledFooter>
            <Container>
                <Row>
                    <Col style={{display: "flex"}}>
                        <Socials target={"_blank"}
                                 href={"https://www.facebook.com/KVHC-Mereta-444705929033808"} rel={"noopener"}>
                            <FacebookIcon/>
                        </Socials>
                        <Socials target={"_blank"}
                                 href={"https://www.instagram.com/kvhc_mereta/"} rel={"noopener"}>
                            <InstagramIcon/>
                        </Socials>
                        <Socials target={"_blank"}
                                 href={"https://www.linkedin.com/company/kvhc-mereta/"} rel={"noopener"}>
                            <LinkedInIcon/>
                        </Socials>
                    </Col>
                    <Col md={8}>
                        <CenterText>
                            <p><Wrap>Katholieke Vlaamse HoogstudentenClub Mereta</Wrap> | <Wrap>Groenstraat 11B, 2860
                                Sint-Katelijne-Waver</Wrap></p>
                            <p><Wrap>© {new Date().getFullYear()} Mereta · Alle rechten voorbehouden</Wrap></p>
                            <p><Wrap>info@mereta.be</Wrap></p>
                            <Credits>created by <Creator target={"_blank"}
                                                         href={"https://www.linkedin.com/in/yorne-thys-ab3632138/"}>Yorne
                                Thys</Creator></Credits>
                        </CenterText>
                    </Col>
                    <Col>{}</Col>
                </Row>
            </Container>
        </StyledFooter>
    )
}

const StyledFooter = styled.footer`
    overflow: hidden;
    background-color: #034073;
    position: absolute;
    padding-top: 16px;
    bottom: 0;
    color: white;
    z-index: 10;
    width: 100%;
`;

const Socials = styled.a`
    padding-right: 10px;
    text-decoration: none;
    display: flex;
    width: 30px;
    height: 30px;
    fill: white;
`;

const CenterText = styled.div`
    text-align: center;
`;

const Wrap = styled.span`
    white-space: nowrap;
`;

const Credits = styled.p`
    font-style: italic;
    font-size: smaller;
`;

const Creator = styled.a`
    text-decoration: none;
    color: white;

    &:hover {
        color: white;
    }
`;

export default Footer;
