import React from "react";
import Container from "react-bootstrap/cjs/Container";
import {BottomPadding, C} from "../../ui/multiUseStyle";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";


function FAQ() {

    return (
        <Container>
            <br/>
            <h1><C>F</C>.A.Q.</h1>
            <br/>
            <Row>
                <Col>
                    <h4>Hoe kan ik lid worden?</h4>
                    <p>Indien je student bent aan Campus De Nayer, kan je volwaardig lid worden van Mereta. Doop en
                        ontgroening gebeuren bij Mereta in stijl en volgens de voorschriften van de Clubcodex. Er komen
                        geen vernederende of mensonwaardige zaken aan te pas! Na het betalen van het lidgeld mag je dan
                        ook aan alle Mereta activiteiten deelnemen.</p>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col>
                    <h4>Mijn studies zijn het belangrijkst. Neemt Mereta niet te veel tijd in?</h4>
                    <p>Natuurlijk zit je hier in de eerste plaats om een diploma te halen. Slechts 1/3 slaagt in het
                        eerste jaar. Nog minder halen hun diploma. Bij Mereta haalt 8/10 het diploma. Je hebt sowieso
                        ontspanning nodig. Mereta organiseert veel verschillende activiteiten, aan jou om te kiezen.
                        Niets is verplicht. Een goed evenwicht tussen studeren en ontspanning vind je alvast bij
                        Mereta.</p>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col>
                    <h4>Moet ik bang zijn van de studentendoop?</h4>
                    <p>Bij Mereta geen mensonwaardige doop of andere wansmakelijke toestanden die eigenlijk niets met
                        een studentenclub te maken hebben. Van het moment iemand de kleuren van Mereta draagt wordt deze
                        persoon behandeld zoals het hoort, met respect en kameraadschap.</p>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col>
                    <h4>Kan ik lid worden als ik niet op kot zit?</h4>
                    <p>Het is helemaal geen vereiste om op kot te zitten. Wel zal het wat moeilijker zijn om na de
                        avondactiviteiten terug thuis te geraken. Sommige activiteiten zijn vrij vroeg gedaan maar je
                        kan ook altijd eens op het kot van een Mereta blijven slapen als je langer wil blijven.
                        Verschillende (oud)praesidiumleden zitten trouwens ook niet op kot.
                    </p>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col>
                    <h4>Lint en codex?</h4>
                    <p>Om deel te nemen aan activiteiten beschik je best over een codex een ledenlint en een
                        studentenpet. Lint en pet worden gedragen als zichtbaar "teken onzer solidariteit". Op de eerste
                        cantus ontvang je alvast je lint. Codices en petten zijn steeds beschikbaar bij het
                        praesidium.</p>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col>
                    <h4>Ik ben geen student aan Campus De Nayer en wil toch lid worden.</h4>
                    <p>Iedereen die kennis wil maken met het echte studentenleven is bij ons aan het juiste adres. Als
                        niet-student van Campus De Nayer kan je voorgedragen worden als ere-lid, je kan jezelf ook
                        voordragen door een mailtje te sturen met daarin je motivatie, waarna je spoedig door ons zal
                        gecontacteerd worden.</p>
                </Col>
            </Row>
            <br/>
            <BottomPadding/>
        </Container>
    )
}

export default FAQ;
