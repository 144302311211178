import './App.css';
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {HashRouter, Route, Switch} from "react-router-dom";
import Home from "./components/pages/home";
import About from "./components/pages/about";
import Activities from "./components/pages/activities";
import Clubhouse from "./components/pages/clubhouse";
import Partners from "./components/pages/partners";
import Contact from "./components/pages/contact";
import Alumni from "./components/pages/alumni";
import {BurgerWithMenu} from "./components/navigation/BurgerMenu";
import Footer from "./components/footer";
import styled from "@emotion/styled";
import ScrollToTop from "./components/navigation/ScrollToTop";
import Rally from "./components/pages/rally";



function App() {
    return (
        <PageContainer>
            <HashRouter basename={"/"}>
                <ScrollToTop/>
                <BurgerWithMenu/>
                    <PageWrap>
                        <Switch onUpdate={() => window.scrollTo(0, 0)}>
                            <Route path={"/Alumni"}>
                                <Alumni/>
                            </Route>
                            <Route path={"/contact"}>
                                <Contact/>
                            </Route>
                            <Route path={"/partners"}>
                                <Partners/>
                            </Route>
                            <Route path={"/clubhuis"}>
                                <Clubhouse/>
                            </Route>
                            <Route path={"/activiteiten"}>
                                <Activities/>
                            </Route>
                            <Route path={"/over_ons"}>
                                <About/>
                            </Route>
                            <Route path={"/sintmichielsrally"}>
                                <Rally/>
                            </Route>
                            <Route path={"/"}>
                                <Home/>
                            </Route>
                        </Switch>
                    </PageWrap>
                <Footer/>
            </HashRouter>
        </PageContainer>
    );
}

const PageContainer = styled.div`
  position: relative;
  min-height: 100vh;
`;

const PageWrap = styled.div`
  padding-bottom: 96px;
`;

export default App;
