import Container from "react-bootstrap/cjs/Container";
import React from "react";
import {BottomPadding, C, ImgResponsive} from "../../ui/multiUseStyle";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import DeNayerOutside from "../../images/deNayerOutside.webp";
import DeNayerAuditorium from "../../images/deNayerAuditoriumgebouw.webp";
import DeNayerAula from "../../images/deNayerAula.webp";


function DeNayer() {
    return (
        <Container className={"mb-4"}>
            <br/>
            <h1><C>D</C>e Nayer</h1>
            <Row>
                <Col sm={7}>
                    <p>
                        Campus De Nayer leidt jaarlijks zo’n 1500 geëngageerde jonge studenten op tot praktisch
                        gerichte
                        industrieel ingenieurs en tot gegradueerden in elektromechanica, autotechnologie,
                        elektronica en
                        mechanische ontwerp- en productietechnologie.
                    </p>
                    <p>
                        Het De Nayer Instituut startte zijn onderwijsactiviteiten in september 1922 op initiatief
                        van
                        kardinaal Mercier en professor De Fays. E.H. Jan-Pieter De Nayer werd aangesteld als eerste
                        directeur van deze eerste Vlaamse Hogere Technische School. Op 6 oktober 1982 verhuisde KIH
                        De
                        Nayer, voorheen BSTI (Bijzondere School voor Technisch Ingenieurs), van het Jef Denynplein
                        in
                        Mechelen naar Sint-Katelijne-Waver.
                    </p>
                    <p>
                        In het academiejaar 1999-2000 werd de afdeling graduaat elektronica van het NARAFI
                        (opgericht in
                        1939) geleidelijk overgeheveld naar campus De Nayer en in september 2004 werd de opleiding
                        Autotechnologie van campus Kardinaal Mercier van Schaarbeek overgebracht naar campus De
                        Nayer,
                        wat zich vertaalde in de oprichting van het Autotechnologisch Centrum ATC.
                    </p>
                    <p>
                        Vanaf 2008-2009 is er een operationeel samenwerkingsverband van campus De Nayer met de
                        Lessius
                        Hogeschool van Antwerpen en sinds 1 oktober 2010 maakt Campus De Nayer deel uit van Lessius
                        Mechelen.
                    </p>
                    <p>
                        Sinds 11 juli 2012 is Lessius Mechelen en Lessius Antwerpen gefuseerd met de KHKempen Geel.
                        Samen vormen ze de Thomas More Hogeschool.
                    </p>
                </Col>
                <Col>
                    <br/>
                    <Row>
                        <Col><ImgResponsive src={DeNayerOutside} alt={""}/></Col>
                        <Col><ImgResponsive src={DeNayerAuditorium} alt={""}/></Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col><ImgResponsive src={DeNayerAula} alt={""}/></Col>
                    </Row>
                </Col>
            </Row>
            <br/>
            <BottomPadding/>
        </Container>
    );
}


export default DeNayer;
