import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import styled from "@emotion/styled";

export function EnlistModal() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    useEffect(() => {
        const timer = setTimeout(() => {
          setShow(true);
        }, 1000);
        return () => clearTimeout(timer);
      }, []);

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Lid worden</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Schrijf je vandaag nog in en maak kennis met de tofste club van De Nayer!
                </p>
                <Center>
                    <JoinButton target={"_blank"} rel={"noreferrer"} href={"https://forms.gle/AKRHCXNtXXkpLAyF7"}>Word Mereta!</JoinButton>
                </Center>
            </Modal.Body>
        </Modal>
    )
}

const Center = styled.div`
    display: flex;
    justify-content: center;    
`

const JoinButton = styled.a`
  position: relative;
  margin: 10px 7px;
  padding: 10px 10px;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 2px;
  color: #034073;
  border: 2px #034073 solid;
  border-radius: 4px;
  text-transform: uppercase;
  overflow: hidden;
  background: none;
  z-index: 1;
  cursor: pointer;
  transition: 0.1s ease-in;
  -o-transition: 0.1s ease-in;
  -moz-transition: 0.1s ease-in;
  -webkit-transition: 0.1s ease-in;

  &:hover {
    color: whitesmoke;
    border: 2px #034073 solid;
    text-decoration: none;
  }

  &:before {
    content: "";
    position: absolute;
    background: #034073;
    bottom: 0;
    left: 0;
    right: 0;
    top: 100%;
    z-index: -1;
    -webkit-transition: top 0.1s ease-in;
  }

  &:hover:before {
    top: 0;
  }
`;