import React from "react";
import styled from "@emotion/styled";
import {Route, Switch} from "react-router";
import History from "../aboutContent/history";
import {HashRouter, NavLink} from "react-router-dom";
import Praesidia from "../aboutContent/praesidia";
import Praesidium from "../aboutContent/praesidium";
import DeNayer from "../aboutContent/deNayer";
import FAQ from "../aboutContent/faq";
import {TopPadding} from "../../ui/multiUseStyle";

function About() {
    return (
        <HashRouter basename={"/over_ons"}>
            <TopPadding/>
            <SubNav>
                    <NavLink to={"/geschiedenis"} activeStyle={{backgroundImage: 'none', backgroundColor: '#1f6ebc'}}>
                        <SubNavItem>Geschiedenis</SubNavItem>
                    </NavLink>
                    <NavLink to={"/praesidium"} activeStyle={{backgroundImage: 'none', backgroundColor: '#1f6ebc'}}>
                        <SubNavItem>Praesidium</SubNavItem>
                    </NavLink>
                    <NavLink to={"/praesidia"} activeStyle={{backgroundImage: 'none', backgroundColor: '#1f6ebc'}}>
                        <SubNavItem>Praesidia</SubNavItem>
                    </NavLink>
                    <NavLink to={"/de_nayer"} activeStyle={{backgroundImage: 'none', backgroundColor: '#1f6ebc'}}>
                        <SubNavItem>De Nayer</SubNavItem>
                    </NavLink>
                    <NavLink to={"/faq"} activeStyle={{backgroundImage: 'none', backgroundColor: '#1f6ebc'}}>
                        <SubNavItem>F.A.Q.</SubNavItem>
                    </NavLink>
            </SubNav>
            <SubNavPadding/>
                    <Switch>
                        <Route path={"/geschiedenis"}>
                            <History/>
                        </Route>
                        <Route path={"/praesidium"}>
                            <Praesidium/>
                        </Route>
                        <Route path={"/praesidia"}>
                            <Praesidia/>
                        </Route>
                        <Route path={"/de_nayer"}>
                            <DeNayer/>
                        </Route>
                        <Route path={"/faq"}>
                            <FAQ/>
                        </Route>
                    </Switch>
        </HashRouter>
    )
}



const SubNavItem = styled.span`
  background-image: linear-gradient(to right, #033864, #034073);
  display: inline-block;
  width: 20%;
  text-align: center;
  font-size: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: white;
  text-decoration: none;
  &:hover {
    background-image: linear-gradient(to right, #155492, #06518f);
    color: #cdc6c6;
    text-decoration: none;
  }
  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const SubNav = styled.div`
  position: fixed;
  width: 100%;
  z-index: 1;
  @media (max-width: 768px) {
    position: relative;
  }
`;

const SubNavPadding = styled.div`
  padding-top: 46px;
`;

export default About;
