import React from "react";
import {C, TopPadding} from "../../ui/multiUseStyle";
import Container from "react-bootstrap/cjs/Container";

function Alumni() {
    return (
        <>
            <TopPadding/>
            <Container>
                <br/>
                <h1><C>A</C>lumni
                </h1>
                <br/>
                <p>
                    Mereta streeft naar een performant netwerk, dat het graduaat - diploma promoot en een persoonlijke,
                    professionele ondersteuning biedt aan al haar leden. Met onze alumni afdeling willen we ons actief
                    profileren op drie niveau’s. We willen een brug vormen tussen:
                    <br/><br/>
                    <ul>
                        <li> Alumni en alumnus: onderhouden van informele contacten tussen u en uw ex-collega
                            studenten
                        </li>
                        <li> Alumni en bedrijven: uitwerken en stimuleren van alle professionele contacten</li>
                        <li> Alumni en studenten: ondersteunen van Studentenvereniging Mereta</li>
                    </ul>
                    Ben je oud-student en wens je graag nog eens af te spreken met je studiegenoten van toen, in ons
                    Clubhuis of op een activiteit van Mereta of Mereta Alumni, neem dan zeker via mail contact met ons
                    op. Wij hebben een formule uitgewerkt waarbij je lid blijft van de vereniging volgens het principe
                    van de Levensbond. Alumni leden zijn oud-studenten die maandelijks hun lidgeld in de vorm van een
                    kleine bijdrage storten en daardoor op de hoogte worden gehouden én gratis welkom zijn op alle
                    Mereta en Mereta-Alumni activiteiten.
                </p>
            </Container>
        </>
    )
}

export default Alumni;
