import Card from "react-bootstrap/Card";
import React from "react";
import Container from "react-bootstrap/cjs/Container";
import CardDeck from 'react-bootstrap/CardDeck'
import {BottomPadding, C} from "../../ui/multiUseStyle";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import styled from "@emotion/styled";


function Praesidium() {
    return (
        <Container>
            <br/>
            <h1><C>P</C>raesidium</h1>
            <Row>
                <CardDeck>
                    <Col lg={4} md={6}>
                        <StyledCard border="secondary" className={"mb-3"}>
                            <Card.Header>X - Praeses</Card.Header>
                            <StyledCardBody>
                                <Card.Title>Wout Mellaerts</Card.Title>
                                <Card.Text>
                                    <List>
                                        <li>Vulgo: Vlucht</li>
                                        <li>Kot-adres: Beatrijsstraat 35, Sint-Katelijne-Waver</li>
                                        <li>Thuis-adres: Rotselaar</li>
                                        <li>Lid sinds: 2022</li>
                                        <li>Studierichting: Voertuigtechnieken (Graduaat)</li>
                                    </List>
                                </Card.Text>
                            </StyledCardBody>
                        </StyledCard>
                    </Col>
                    <Col lg={4} md={6}>
                        <StyledCard border="secondary" className={"mb-3"}>
                            <Card.Header>XX - Vice-Praeses</Card.Header>
                            <StyledCardBody>
                                <Card.Title>Thijs Ribbens</Card.Title>
                                <Card.Text>
                                    <List>
                                        <li>Vulgo: /</li>
                                        <li>Kot-adres: Stationsstraat 60, Sint-Katelijne-Waver</li>
                                        <li>Thuis-adres: Essen</li>
                                        <li>Lid sinds: 2023</li>
                                        <li>Studierichting: Elektromechanica (PB)</li>
                                    </List>
                                </Card.Text>
                            </StyledCardBody>
                        </StyledCard>
                    </Col>
                    <Col lg={4} md={6}>
                        <StyledCard border="secondary" className={"mb-3"}>
                            <Card.Header>XXX - Ab-Actis</Card.Header>
                            <StyledCardBody>
                                <Card.Title>Kimi Boogaerts</Card.Title>
                                <Card.Text>
                                    <List>
                                        <li>Vulgo: /</li>
                                        <li>Kot-adres: Caputsteenstraat 76, Mechelen</li>
                                        <li>Thuis-adres: Nieuwerkerken</li>
                                        <li>Lid sinds: 2023</li>
                                        <li>Studierichting: Autotechnologie (PB)</li>
                                    </List>
                                </Card.Text>
                            </StyledCardBody>
                        </StyledCard>
                    </Col>
                    <Col lg={4} md={6}>
                        <StyledCard border="secondary" className={"mb-3"}>
                            <Card.Header>XXXX - Quaestor</Card.Header>
                            <StyledCardBody>
                                <Card.Title>Berten Torfs</Card.Title>
                                <Card.Text>
                                    <List>
                                        <li>Vulgo: /</li>
                                        <li>Kot-adres: /</li>
                                        <li>Thuis-adres: Duffel</li>
                                        <li>Lid sinds: 2023</li>
                                        <li>Studierichting: Automotive Technology (Engelstalige PB)</li>
                                    </List>
                                </Card.Text>
                            </StyledCardBody>
                        </StyledCard>
                    </Col>
                    {/* <Col lg={4} md={6}>
                        <StyledCard border="secondary" className={"mb-3"}>
                            <Card.Header>Feestleider</Card.Header>
                            <StyledCardBody>
                                <Card.Title>/</Card.Title>
                                <Card.Text>
                                    <List>
                                        <li>Vulgo: /</li>
                                        <li>Kot-adres: / </li>
                                        <li>Thuis-adres: /</li>
                                        <li>Lid sinds: /</li>
                                        <li>Studierichting: /</li>
                                    </List>
                                </Card.Text>
                            </StyledCardBody>
                        </StyledCard>
                    </Col> */}
                    <Col lg={4} md={6}>
                        <StyledCard border="secondary" className={"mb-3"}>
                            <Card.Header>Spocul</Card.Header>
                            <StyledCardBody>
                                <Card.Title>Jolan Claeys</Card.Title>
                                <Card.Text>
                                    <List>
                                        <li>Vulgo: /</li>
                                        <li>Kot-adres: /</li>
                                        <li>Thuis-adres: Jolan Claeys</li>
                                        <li>Lid sinds: 2023</li>
                                        <li>Studierichting: Autotechnologie (PB)</li>
                                    </List>
                                </Card.Text>
                            </StyledCardBody>
                        </StyledCard>
                    </Col>
                    <Col lg={4} md={6}>
                        <StyledCard border="secondary" className={"mb-3"}>
                            <Card.Header>SM - Schachtenmeester</Card.Header>
                            <StyledCardBody>
                                <Card.Title>Jarne Vreys</Card.Title>
                                <Card.Text>
                                    <List>
                                        <li>Vulgo: /</li>
                                        <li>Kot-adres: Beatrijsstraat 35, Sint-Katelijne-Waver</li>
                                        <li>Thuis-adres: Ham</li>
                                        <li>Lid sinds: 2022</li>
                                        <li>Studierichting: Autotechnologie (PB)</li>
                                    </List>
                                </Card.Text>
                            </StyledCardBody>
                        </StyledCard>
                    </Col>
                </CardDeck>
            </Row>
            <br/>
            <BottomPadding/>
        </Container>
    )
}

const List = styled.ul`
  padding-left: 18px;
`;

const StyledCard = styled(Card)`
  min-height: 270px;
`;

const StyledCardBody = styled(Card.Body)`
  padding-bottom: 0;
`;

export default Praesidium;
