import React, {useState} from "react";
import styled from "@emotion/styled";
import Navigation from "./navigation";


const StyledBurger = styled.div`
  position: fixed;
  width: 2.5rem;
  height: 2.5rem;
  top: 10px;
  right: 2vw;
  z-index: 20;
  display: none;
  @media (max-width: 1250px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
  div {
    position: relative;
    width: 2.5rem;
    height: 0.25rem;
    background-color: ${({ open }) => open ? '#033864' : '#ffffff'};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-of-type(1) {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    &:nth-of-type(2) {
      transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${({ open }) => open ? 0 : 1};
    }
    &:nth-of-type(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;


export function BurgerWithMenu() {
    const [open, setOpen] = useState(false);
    return <>
        <StyledBurger open={open} onClick={() => setOpen(!open)}>
            <div/>
            <div/>
            <div/>
        </StyledBurger>
        <Navigation open={open} setOpen={setOpen}/>
    </>
}